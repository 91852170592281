$(document).ready(function() {

	// Modal

	let modal       = new bootstrap.Modal(document.getElementById('baseModal'), {
		keyboard: false,
		backdrop: 'static',
		focus: true
	});
	let modaltitle  = $('#modal-title');
	let modaltext   = $('#modal-body');



	function submitAJAXForm( _this ) {

		event.preventDefault();

		modaltitle.text('');
		modaltext.text('');

		let spinner = $('#loader');

		let formObj     = _this.parents( 'form' ),
			actionURL   = formObj.find( '[name="action"]' ).val(), //cambiato
			redirectVal = formObj.find( '[name="redirectval"]' ).val();

		if( actionURL != '' && actionURL != undefined ) {
			/* gestione attachment */
			let formID = '';
			formObj.each(function(index, element) {
				formID = element.id;
			});

			let formData = new FormData($('#'+formID)[0]);
			let elem = document.getElementById(formID).elements;
			let i = 0;
			let action = '';
			while( i < elem.length )    {
				if(elem[i].name == 'action'){
					action = elem[i].value;
				}
				//gestione file multipli su stesso input
				let index = 0;
				if(elem[i].files != null) {
					if (elem[i].files.length > 1) {
						while (index < elem[i].files.length) {
							formData.append(elem[i].files[index].name, elem[i].files[index]);
							index++;
						}
					}
				}
				formData.append(elem[i].name, elem[i].value);
				i++;
			}
			//console.log(formData);

			spinner.show();

			$.ajax({
				type	    : 'POST',
				url		    : actionURL,
				data	    : formData ? formData : formObj.serialize(),
				dataType    : 'json',
				cache       : false,
				contentType : false,
				processData : false,
				success: function ( result ) {

					spinner.hide();
					formObj.find( 'input[name=action],input[name=g-recaptcha-response],input[name=invoice-required]' ).remove();
					// formObj.find( 'input[name=rememberCheckbox]' ).remove();

					if ( typeof ( result ) !== 'undefined' && result !== null ) {
						if (typeof result === 'string' || result instanceof String){
							result = $.parseJSON( result );
						}

						formObj.find( 'input[type=text],input[type=email],input[type=tel],input[type=password],textarea' ).each( function () {
							$( this ).val('');
							$( this ).removeClass( 'error' );
							// Anche label? Manca select
						});
						formObj.find( '.g-recaptcha' ).removeClass( 'error' );
						formObj.find( 'input[type=checkbox],input[type=radio]' ).prop( 'checked', false );
						if( formObj.find( '.g-recaptcha' ).length > 0 ) {
							grecaptcha.reset();
						}


						if(result.status || (result.success && result.success != 'error')){
							window.dataLayer = window.dataLayer || []
							dataLayer.push({
								'event': 'formSubmission',
								'formType': formObj.find( '[name="formname"]' ).val(),
								'formPosition': formObj.find( 'input[name="formposition"]' ).val()
							});

							if(result.status || (result.success && result.success != 'false')) {
								modaltitle.html(result.title).text();
								modaltext.html(result.message).text();
								modal.show();
								if(typeof result.redirectVal !== 'undefined') {
									$("#baseModal").on('hidden.bs.modal', function () {
										window.location.href = result.redirectVal;
									});
								}
							}else{
								modaltitle.html(result.title).text();
								modaltext.html(result.message).text();
								modal.show();
							}
						}else{
							modaltitle.html(result.title).text();
							modaltext.html(result.message).text();
							modal.show();
						}
					}
				}
			});
		}
	}


	$(document).on('click', '.submit', function (event) {
		event.preventDefault();
		let _this = $(this);
		let error = false,
			captchaFlag = false,
			formObj = _this.parents('form'),
			emailFormat = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/,
			telFormat = /[0-9 -()+]{8,}?$/,
			actionURL = formObj.attr('action'),
			gRecaptchaV3 = _this.attr('data-sitekey') || '';

		formObj.find('input[required]').removeClass('error');
		formObj.find('.help-block').text('');

		formObj.find('input[required], textarea[required]').each(function () { //======>.required
			let __this      = $(this),
				fieldVal    = __this.val(),
				fieldValMex = __this.attr('data-validationmessage');
			if (fieldVal == '' || fieldVal == undefined) {
				error = true;
				__this.addClass('error');
				__this.parent().find('.help-block').text(fieldValMex);
			} else if (__this.attr('type') == 'checkbox' && !__this.prop('checked')) {
				error = true;
				__this.addClass(fieldValMex);
				__this.parent().find('.help-block').text(fieldValMex);
			} else if (__this.attr('type') == 'email' && !emailFormat.test(fieldVal)) {
				error = true;
				__this.addClass(fieldValMex);
				__this.parent().find('.help-block').text('no valid email!');
			} else if (__this.attr('type') == 'tel' && !telFormat.test(fieldVal)) {
				error = true;
				__this.addClass(fieldValMex);
				__this.parent().find('.help-block').text('no valid tel!');
			}
		});
		console.log(_this);
		// Google reCaptcha Verify
		if (typeof (grecaptcha) !== 'undefined' && grecaptcha !== null) {
			if (formObj.find('.g-recaptcha').length > 0) { // For Version 2
				let gResponse = grecaptcha.getResponse();
				if (!(gResponse.length)) {
					error = true;
					formObj.find('.g-recaptcha').addClass('error');
				}
			} else if (gRecaptchaV3 != '' && gRecaptchaV3 != undefined) { // For Version 3

				captchaFlag = true;
				formObj.find('input[name=action],input[name=g-recaptcha-response]').remove();
				grecaptcha.ready(function () {
					grecaptcha.execute(gRecaptchaV3, {action: 'form'}).then(function (token) {
						formObj.prepend('<input type="hidden" name="g-recaptcha-response" value="' + token + '">');
						formObj.prepend('<input type="hidden" name="action" value="form">');

						if (!error) {
							submitAJAXForm(_this);
						}
					});
				});
			}
		}
	});

});

function uploadedFileList(current){
	let output = '';
	for(let i =0; i < current.files.length; i++){
		output += output==""?current.files[i].name:", "+current.files[i].name;
	}
	console.log(output);
	$('#upload-file-info').html(output);
}



const ready = (callback) => {

	if (document.readyState != 'loading') callback();
	else document.addEventListener('DOMContentLoaded', callback);

}


// Doc ready

ready(() => {


	const toggler       = document.querySelector('.navbar-toggler');
	let navbarHeight            = $('#header').outerHeight(true);
	let delta           = 5;
	let lastScrollTop   = 0;
	let togglerOpen     = false;
	let didScroll;


	function getWindowWidth() {
		return $( window ).width();
	}

	function togglerState() {
		togglerOpen = !togglerOpen;
	}

	function hasScrolled() {

		if(getWindowWidth() < 991) {

			let scrollTop = $(this).scrollTop();

			// Delta min scroll
			if (Math.abs(lastScrollTop - scrollTop) <= delta) {
				return;
			}

			// If top remove scrolldown
			if (scrollTop <= 0) {
				$('body').removeClass('scroll-down')
				return;
			}

			if (scrollTop > lastScrollTop && scrollTop > navbarHeight) {
				// Scroll Down
				if (!togglerOpen) {
					$('[data-bs-toggle="dropdown"]').dropdown('hide').blur();
					$('body').removeClass('scroll-up').addClass('scroll-down');
				}
			} else {
				// Scroll Up
				if (!togglerOpen) {
					if (scrollTop + $(window).height() < $(document).height()) {
						$('body').removeClass('scroll-down').addClass('scroll-up');
					}
				}
			}

			lastScrollTop = scrollTop;

		}
	}

	toggler.addEventListener('click', togglerState);



	$(window).scroll(function(){
		if (!togglerOpen) {
			didScroll = true;
		}
	});

	$(window).on('resize', function(){
		$('body').removeClass('scroll-down').addClass('scroll-up');
	});

	// Toggle scroll function state for collapse and anchor links
	$('#header [data-bs-toggle="collapse"]').click(function() {
		togglerOpen = true;
	});
	$('.navbar-collapse').on('hidden.bs.collapse', function () {
		togglerOpen = false;
	});
	$('.navbar-collapse a[href^="#"]:not([href="#"])').click(function () {
		togglerOpen = true;
	});


	// Scroll func performance
	setInterval(function() {
		toggleBacktotop();
		if (didScroll) {
			hasScrolled();
			didScroll = false;
		}
	}, 250);




	// SMOOTH SCROLL TO ANCHORS

	const anchors = document.querySelectorAll('a[href^="#"]:not([href="#"])');

	anchors.forEach(trigger => {

		trigger.onclick = function(e) {

			e.preventDefault();
			let hash = this.getAttribute('href');
			let target = document.querySelector(hash);
			let elementPosition = target.offsetTop;
			let headerOffset = $('#header').outerHeight(true);
			let offsetPosition = elementPosition - headerOffset;

			window.scrollTo({

				top: offsetPosition,
				behavior: 'smooth'

			});

			// Wait scroll function to resume scroll func
			setTimeout(function(){
				togglerOpen = false;
			}, 1000);

		};
	});




	// BACK TO TOP

	const topButton = document.getElementById('top');

	function backToTop() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	function toggleBacktotop() {
		if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
			topButton.style.display = 'block';
		} else {
			topButton.style.display = 'none';
		}
	}

	if(topButton) {
		topButton.addEventListener('click', backToTop);
	}




	// LAZY LOADING IMAGES

	// let lazyloadImages;
	//
	// if ('IntersectionObserver' in window) {
	//
	// 	lazyloadImages = document.querySelectorAll('.lazyload');
	//
	// 	let imageObserver = new IntersectionObserver( function(entries, observer) {
	//
	// 		entries.forEach((entry) => {
	//
	// 			if (entry.isIntersecting) {
	//
	// 				let image = entry.target;
	// 				image.src = image.dataset.src;
	// 				image.classList.remove('lazyload');
	// 				image.classList.add('lazyloaded');
	// 				imageObserver.unobserve(image);
	//
	// 			}
	//
	// 		});
	//
	// 	}, {
	// 		rootMargin: "300px 0px 300px 0px",
	// 		threshold: 0
	// 	});
	//
	// 	lazyloadImages.forEach(function(image) {
	//
	// 		imageObserver.observe(image);
	//
	// 	});
	//
	// } else {
	//
	// 	let lazyloadThrottleTimeout;
	//
	// 	lazyloadImages = document.querySelectorAll('.lazyload');
	//
	// 	function lazyload () {
	//
	// 		if(lazyloadThrottleTimeout) {
	//
	// 			clearTimeout(lazyloadThrottleTimeout);
	//
	// 		}
	//
	// 		lazyloadThrottleTimeout = setTimeout(function() {
	//
	// 			let scrollTop = window.pageYOffset;
	//
	// 			lazyloadImages.forEach(function(img) {
	//
	// 				if(img.offsetTop < (window.innerHeight + scrollTop + 300)) {
	//
	// 					img.src = img.dataset.src;
	// 					img.classList.remove('lazyload');
	// 					img.classList.add('lazyloaded');
	//
	// 				}
	//
	// 			});
	//
	// 			if(lazyloadImages.length == 0) {
	//
	// 				document.removeEventListener('scroll', lazyload);
	// 				window.removeEventListener('resize', lazyload);
	// 				window.removeEventListener('orientationChange', lazyload);
	//
	// 			}
	//
	// 		}, 20);
	//
	// 	}
	//
	// 	document.addEventListener('scroll', lazyload);
	// 	window.addEventListener('resize', lazyload);
	// 	window.addEventListener('orientationChange', lazyload);
	// }


	// Clone desktop sidebar single product filters to mobile aside offcanvas and vice versa

	const mediaQuery = '(max-width: 1199px)';
	const mediaQueryList = window.matchMedia(mediaQuery);

	if (mediaQueryList.matches) {

		$('#offcanvasFilters').append($('#sidebarFilters').children().clone());
		$('#sidebarFilters').empty();

	}

	mediaQueryList.addEventListener('change', event => {

		if (event.matches) {

			$('#offcanvasFilters').append($('#sidebarFilters').children().clone());
			$('#sidebarFilters').empty();

		} else {

			$('#sidebarFilters').append($('#offcanvasFilters').children().clone());
			$('#offcanvasFilters').empty();

		}

	})





	// SWIPER CAROUSELS

	// Bug pagination with loop: total number of slides must be >= slidesPerView * 2 => use rewind

	const swiperProdRelated = new Swiper('#swiperProdRelated', {

		// loop: true,
		rewind: true,
		slidesPerView: 1,
		spaceBetween: 10,
		watchSlidesProgress: true,
		lazy: {
			loadPrevNext: true,
		},
		grabCursor: true,
		autoplay: true,
		breakpoints: {
			1200: {
				slidesPerView: 4
			},
			991: {
				slidesPerView: 3
			},
			576: {
				slidesPerView: 2
			}
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		}

	});

	const swiperStaff = new Swiper('#swiperStaff', {

		// loop: true,
		rewind: true,
		slidesPerView: 1,
		spaceBetween: 20,
		watchSlidesProgress: true,
		lazy: {
			loadPrevNext: true,
		},
		grabCursor: true,
		autoplay: true,
		breakpoints: {
			991: {
				slidesPerView: 2
			}
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		}

	});

	const swiperImgtextProd = new Swiper('#swiperImgtextProd', {

		// loop: true,
		rewind: true,
		slidesPerView: 1,
		spaceBetween: 20,
		watchSlidesProgress: true,
		lazy: {
			loadPrevNext: true,
		},
		grabCursor: true,
		autoplay: true,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		}

	});


});